import { defineNuxtPlugin, useRuntimeConfig } from '#app';
import { GrowthBook } from '@growthbook/growthbook';
import { v4 as uuidv4 } from 'uuid';

const parseJwt = (token: string) => {
  try {
    if (import.meta.server) {
      return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
    }

    return JSON.parse(atob(token.split('.')[1]));
  } catch {
    return null;
  }
};

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();

  if (
    !config.public.growthbook?.clientKey ||
    !config.public.growthbook?.enabled
  ) {
    return {
      provide: {
        growthbook: null,
      },
    };
  }

  const growthbookInstance = new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey: config.public.growthbook.clientKey,
    enableDevMode: config.public.isLocal || false,
    trackingCallback: (experiment, result) => {
      // Is changed to Mixpanel after the Mixpanel plugin is loaded
      console.log('Viewed Experiment', {
        experimentId: experiment.key,
        variationId: result.key,
      });
    },
  });

  // Get Growthbook ID cookie and generate uuid if not already set. This is only used if Mixpanel is not loaded and won't override the ID with its distinct ID
  const growthbookIdCookie = useGrowthbookIdCookie();

  if (!growthbookIdCookie.value) {
    growthbookIdCookie.value = uuidv4();
  }

  // Get IDS auth cookie and parse it to set idsUserId for logged in users
  const idsAuthTokenCookie = useAccountAuthTokenCookie();
  let idsUserId = '';

  if (idsAuthTokenCookie.value) {
    const parsedJwt = parseJwt(idsAuthTokenCookie.value);
    idsUserId = parsedJwt?.sub || '';
  }

  growthbookInstance.setAttributes({
    id: growthbookIdCookie.value,
    idsUserId,
  });

  const initializeGrowthbook = async () => {
    try {
      const initResult = await growthbookInstance.init({ streaming: true });

      if (initResult.error) {
        throw initResult.error;
      }

      return growthbookInstance;
    } catch (e) {
      console.error('Error initializing Growthbook:', e);
      return null;
    }
  };

  // Initialize GrowthBook
  const initializedGrowthbookInstance = await initializeGrowthbook();

  return {
    provide: {
      growthbook: initializedGrowthbookInstance,
    },
  };
});
